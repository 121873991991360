import SimpleBar from '../library/simplebar'
import isMobile from './is-mobile'

export function stickyTableHeaders(tableId, simpleBar, controllerOptions) {
  const table = document.querySelector(tableId)
  const tableContainer = document.getElementById('table-sticky-headers')
  const stickyColumns = table.dataset.stickyColumns
  const stickyColumnWidth = stickyColumns ? Array(parseInt(stickyColumns))?.fill(0) : 0
  let initSimpleBarEvents = false
  if (!simpleBar) {
    simpleBar = new SimpleBar(tableContainer, { autoHide: false })
    initSimpleBarEvents = true
  }

  if (stickyColumns) {
    let stickyColumnOffsetLeft = 250
    stickyColumnWidth.map((item, index) => {
      if (index === 0 ) {
        stickyColumnWidth[index] = stickyColumnOffsetLeft
      } else {
        stickyColumnOffsetLeft += table.querySelectorAll('thead th')[index].offsetWidth
        stickyColumnWidth[index] = stickyColumnOffsetLeft
      }
    })
  }

  setTimeout(() => {
    if (initSimpleBarEvents) {
      simpleBar.getScrollElement().addEventListener('scroll', () => {
        if (table.querySelector('thead').style.position === 'fixed') {
          const sectionScrollLeft = document.querySelector('.simplebar-content-wrapper').scrollLeft
          table.querySelector('thead').style.left = isMobile() ? -sectionScrollLeft + 30 + 'px' : -sectionScrollLeft + 250 + 'px'
        } else {
          table.querySelector('thead').style.left = 0
        }
      })
    }
  }, 1000)

  const enableStickyness = () => {
    table.querySelector('thead').style.position = 'fixed'
    table.querySelector('thead').style.top = isMobile() ? 0 : '55px'
    stickyColumnWidth.map((item, index) => {
      table.querySelectorAll('thead th')[index + 1].style.left = isMobile() ? 0 : item + 'px'
    })
    const sectionScrollLeft = document.querySelector('.simplebar-content-wrapper').scrollLeft
    table.style.marginTop = '44px'
    table.querySelector('thead').style.left = isMobile() ? -sectionScrollLeft + 15 + 'px' : -sectionScrollLeft + 250 + 'px'
  }

  const disableStickyness = () => {
    table.querySelector('thead').style.position = 'relative'
    table.querySelector('thead').style.top = '0px'
    table.style.marginTop = '0'
    stickyColumnWidth.map((item, index) => {
      table.querySelectorAll('thead th')[index + 1].style.left = isMobile() ? 0 : (item - 250) + 'px'
    })
    table.querySelector('thead').style.left = 0
  }

  table.querySelectorAll('th').forEach((cell, index) => {
    if (!controllerOptions.thOptionsWidth) {
      controllerOptions.thOptionsWidth = {}
    }
    let width = cell.offsetWidth
    const searchQueryLength = cell.querySelector('[type="text"]')?.value.length
    if (searchQueryLength && searchQueryLength * 15 > width) {
      width = searchQueryLength * 15
    }
    if (initSimpleBarEvents || !controllerOptions.thOptionsWidth[index]) {
      controllerOptions.thOptionsWidth[index] = width
      cell.style.minWidth = width + 'px'
      cell.style.maxWidth = width + 'px'
    } else {
      width = controllerOptions.thOptionsWidth[index]
      cell.style.minWidth = width + 'px'
      cell.style.maxWidth = width + 'px'
    }
  })

  // set cell width only to first row to avoid performance issues
  const firstTableRow = table.querySelector('tbody tr')

  if (firstTableRow) {
    firstTableRow.querySelectorAll('td').forEach((cell, index) => {
      const width = controllerOptions.thOptionsWidth[index]
      cell.style.minWidth = width + 'px'
      cell.style.maxWidth = width + 'px'
    })
  }

  const trackStickyness = () => {
    const tablePosition = tableContainer.getBoundingClientRect()
    const topOffset = isMobile() ? 0 : 55
    if (tablePosition.top < topOffset) {
      enableStickyness()
    } else {
      disableStickyness()
    }
  }

  if (initSimpleBarEvents) {
    // desktop
    document.querySelector('.section-container').addEventListener('wheel', () => {
      trackStickyness()
    }, true)

    // mobile
    document.querySelector('.section-container').addEventListener('touchmove', (e) => {
      trackStickyness()
    }, true)

    // simplebar manual scroll
    simpleBar.getScrollElement().addEventListener('scroll', () => {
      trackStickyness()
    })
  }
  return simpleBar
}
