import { Controller } from 'stimulus'

const UNCONFIRMED = 'unconfirmed'
const DONT_HAVE = 'dont_have'
export default class extends Controller {
  static targets = ['commentField', 'status']

  static values = {
    status: String,
  }

  connect() {
    document.addEventListener('keydown', function(e) {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
  }

  confirmMissingTakeItem(e) {
    e.preventDefault()
    e.stopPropagation()
    const currentTarget = e.currentTarget
    const type = currentTarget.dataset['actionType']
    if ([UNCONFIRMED, DONT_HAVE].includes(type)) {
      if (this.hasCommentFieldTarget && !this.commentFieldTarget.checkValidity()) {
        return this.commentFieldTarget.reportValidity()
      }
    }
    this.statusTarget.value = type
    Rails.fire(e.currentTarget.closest('form'), 'submit')
  }
}
