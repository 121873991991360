import { Controller } from 'stimulus'

export default class Dropdown extends Controller {
  static targets = ['menu', 'input', 'selectedValue']

  connect() {
    this.updateOptions()
  }

  updateOptions() {
    this.selectedValueTarget.innerText = this.inputTarget.value
    this.menuTarget.querySelectorAll('button').forEach((button) => {
      if (button.dataset.value === this.inputTarget.value) {
        button.classList.add('active', 'tw-font-semibold')
      } else {
        button.classList.remove('active', 'tw-font-semibold')
      }
    })
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    if (event.target.dataset.value) {
      this.inputTarget.value = event.target.dataset.value
      this.updateOptions()
    }
    this.menuTarget.classList.toggle('tw-hidden')
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('tw-hidden')) {
      this.menuTarget.classList.toggle('tw-hidden')
    }
  }
}
