export class ScreenNotification {
  constructor(params) {
    this.inScreenNotification = params.inScreenNotification
  }

  show(notificationString, type, notificationAppendString) {
    const translatedMessage = this.message(notificationString, notificationAppendString)
    if (!translatedMessage) {
      return
    }
    let notificationElement = document.querySelector('div[data-barcode-scanner-target="inScreenNotification"]')
    if (this.inScreenNotification) {
      notificationElement = this.inScreenNotification
    }

    if (notificationElement != null) {
      clearTimeout(this.inScreenNotificationTimeout)
      // notificationElement.style.display = 'block'
      // notificationElement.style.opacity = 1
      notificationElement.innerHTML = translatedMessage
      notificationElement.classList.remove('alert-success')
      notificationElement.classList.remove('alert-error')
      notificationElement.classList.remove('alert-primary')
      notificationElement.classList.remove('alert-warning')
      notificationElement.classList.remove('d-none')

      switch (type) {
        case 'success':
          notificationElement.classList.add('alert-success')
          // notificationElement.classList.remove('text-warning', 'text-danger')
          break
        case 'error':
          notificationElement.classList.add('alert-error')
          break
          // notificationElement.classList.remove('text-success', 'text-danger')
        case 'info':
          notificationElement.classList.add('alert-primary')
          break
        default:
          notificationElement.classList.add('alert-warning')
          // notificationElement.classList.remove('text-success', 'text-danger')
      }

      this.inScreenNotificationTimeout = setTimeout(() => {
        notificationElement.classList.add('d-none')
        notificationElement.classList.remove('alert-success')
        notificationElement.classList.remove('alert-error')
        notificationElement.classList.remove('alert-primary')
        notificationElement.classList.remove('alert-warning')
        // notificationElement.style.display = 'none'
      }, 4000)
    }
  }

  message(notification, appendString) {
    switch (notification) {
      case 'barcode_already_confirmed_same_resource':
        return false
      case 'barcode_not_valid':
        return window.I18n.t('js.barcode_not_valid')
      case 'not_found':
        return window.I18n.t('js.barcode_not_valid')
      case 'barcode_invalid_url':
        return window.I18n.t('js.barcode_invalid_url')
      case 'barcode_not_assigned':
        return window.I18n.t('js.barcode_not_assigned')
      case 'barcode_already_in_use_elsewhere':
        return window.I18n.t('js.barcode_already_in_use_elsewhere')
      case 'barcode_direct_access_token_already_in_use_elsewhere':
        return window.I18n.t('js.barcode_direct_access_token_already_in_use_elsewhere')
      case 'barcode_object_has_already_been_assigned':
        return window.I18n.t('js.barcode_object_has_already_been_assigned')
      case 'barcode_added_to_list':
        return window.I18n.t('js.barcode_added_to_list') + ' (Barcode: ' + appendString + ')'
      case 'barcode_item_checked_out':
        return window.I18n.t('js.barcode_item_checked_out') + ' ' + appendString
      case 'barcode_already_checked_out_same_resource':
        return window.I18n.t('js.barcode_already_checked_out_same_resource') + ' ' + appendString
      case 'barcode_insufficient_permissions':
        return window.I18n.t('js.barcode_insufficient_permissions') + ' ' + appendString
      case 'barcode_item_confirmed':
        return window.I18n.t('js.barcode_item_confirmed') + ' ' + appendString
      case 'barcode_already_in_use_with_item':
        return window.I18n.t('js.barcode_already_in_use_with_item')
      case 'cannot_convert_stock_item_to_twin':
        return window.I18n.t('js.cannot_convert_stock_item_to_twin')
      case 'move_barcode_from_another_blueprint':
        return window.I18n.t('js.move_barcode_from_another_blueprint')
      case 'assigned':
        return window.I18n.t('js.assigned')
      case 'not_assigned':
        return window.I18n.t('js.not_assigned')
      case 'item_belongs_to_different_category':
        return window.I18n.t('js.item_belongs_to_different_category')
      case 'invalid_barcode':
      default:
        return notification
    }
  }

  notify(notification, type) {
    const message = this.message(notification)

    switch (type) {
      case 'success':
        $.notify('<i class="fas fa-check mr-2"></i>' + message, 'success')
        break
      case 'error':
        $.notify('<i class="far fa-times-circle mr-2"></i>' + message, 'error')
        break
      case 'warning':
        $.notify('<i class="fas fa-exclamation-triangle mr-2"></i>' + message, 'warning')
        break
    }
  }
}
