import * as ScanditSDK from 'scandit-sdk'
import { EventClass } from './event_class'

const SCANDIT_ENGINE_LOCATION = 'https://cdn.jsdelivr.net/npm/scandit-sdk@6.x/build/'

export class ScanditScanner extends EventClass {
  init(element) {
    return ScanditSDK.configure(window.SCANDIT_LICENSE_KEY, {
      engineLocation: SCANDIT_ENGINE_LOCATION,
    }).then(() => {
      return ScanditSDK.BarcodePicker.create(element, {
        playSoundOnScan: true,
        vibrateOnScan: true,
        laserArea: false,
        accessCamera: true,
        enableCameraSwitcher: false,
      }).then((barcodePicker) => {
        const scanSettings = new ScanditSDK.ScanSettings({
          enabledSymbologies: this.enabledSymbologies(),
          codeDuplicateFilter: 4000, // Minimum delay between duplicate results
        })
        this.enabledSymbologies().forEach((symbol) => {
          const symbologySettings = scanSettings.getSymbologySettings(symbol)
          symbologySettings.setColorInvertedEnabled(true)
        })

        barcodePicker.applyScanSettings(scanSettings)
        barcodePicker.on('scan', (scanResult) => {
          this.emit('scan', { data: scanResult.barcodes[0].data, symbology: scanResult.barcodes[0].symbology } )
        })

        return barcodePicker
      })
    })
  }

  availableCameras() {
    return ScanditSDK.CameraAccess.getCameras()
  }

  enabledSymbologies() {
    return [
      // ScanditSDK.Barcode.Symbology.AZTEC,
      ScanditSDK.Barcode.Symbology.CODABAR,
      ScanditSDK.Barcode.Symbology.CODE11,
      ScanditSDK.Barcode.Symbology.CODE25,
      ScanditSDK.Barcode.Symbology.CODE32,
      ScanditSDK.Barcode.Symbology.CODE39,
      ScanditSDK.Barcode.Symbology.CODE93,
      ScanditSDK.Barcode.Symbology.CODE128,
      ScanditSDK.Barcode.Symbology.DATA_MATRIX,
      // ScanditSDK.Barcode.Symbology.DOTCODE,
      ScanditSDK.Barcode.Symbology.EAN8,
      ScanditSDK.Barcode.Symbology.EAN13,
      ScanditSDK.Barcode.Symbology.FIVE_DIGIT_ADD_ON,
      // ScanditSDK.Barcode.Symbology.GS1_DATABAR,
      // ScanditSDK.Barcode.Symbology.GS1_DATABAR_EXPANDED,
      // ScanditSDK.Barcode.Symbology.GS1_DATABAR_LIMITED,
      // ScanditSDK.Barcode.Symbology.IATA_2_OF_5,
      ScanditSDK.Barcode.Symbology.INTERLEAVED_2_OF_5,
      // ScanditSDK.Barcode.Symbology.KIX,
      // ScanditSDK.Barcode.Symbology.LAPA4SC,
      // ScanditSDK.Barcode.Symbology.MATRIX_2_OF_5,
      // ScanditSDK.Barcode.Symbology.MAXICODE,
      // ScanditSDK.Barcode.Symbology.MICRO_PDF417,
      // ScanditSDK.Barcode.Symbology.MICRO_QR,
      // ScanditSDK.Barcode.Symbology.MSI_PLESSEY,
      // ScanditSDK.Barcode.Symbology.PDF417,
      ScanditSDK.Barcode.Symbology.QR,
      // ScanditSDK.Barcode.Symbology.RM4SCC,
      ScanditSDK.Barcode.Symbology.TWO_DIGIT_ADD_ON,
      ScanditSDK.Barcode.Symbology.UPCA,
      ScanditSDK.Barcode.Symbology.UPCE,
      // ScanditSDK.Barcode.Symbology.USPS_INTELLIGENT_MAIL,
    ]
  }
}
