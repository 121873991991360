import { Controller } from 'stimulus'
import { Scheduler, PresetManager, StringHelper, LocaleManager,
  ViewPreset, StateProvider } from '@bryntum/scheduler/scheduler.module.min.js'
import './custom.locale.De'
import './custom.locale.Es'
import './custom.locale.Fr'
import SwalStyles from '../utils/swal_styles'

require('@bryntum/scheduler/scheduler.stockholm.min.css')

export default class extends Controller {
  static targets = ['scheduler'];
  CUSTOM_CHECKOUT = 'custom_checkout';

  static values = {
    resources: Array,
    events: Array,
    locale: String,
    enableTime: Boolean,
  }

  connect() {
    LocaleManager.applyLocale(this.setLocale())
    PresetManager.add([this.monthPreset(), this.weekNumberPreset()])
    const requiredPresetIds = { hourAndDay: 1, weekAndDayLetter: 1, weekNumberAndYear: 1, dayMonth: 1 }
    const presets = PresetManager.records.filter((p) => requiredPresetIds[p.id])

    // eslint-disable-next-line
    let self = this
    StateProvider.setup('local')

    this.scheduler = new Scheduler( {
      appendTo: this.schedulerTarget,
      stateId: 'SchedulerState',
      autoHeight: true,
      infiniteScroll: true,
      resources: this.resourcesValue,
      events: this.eventsValue,
      eventStyle: 'rounded',
      eventColor: 'blue',
      presets,
      viewPreset: 'weekAndDayLetter',
      weekStartDay: '1',
      milestoneLayoutMode: 'data',
      columns: [
        { type: 'template', text: 'L{Name}', field: 'name', width: 170,
          enableCellContextMenu: false, editor: false,
          template: (recordInfo) => this.getTemplate(recordInfo.record.data) },
      ],
      features: {
        cellTooltip: {
          hoverDelay: 300,
          textContent: true,
          header: {
            titleAlign: 'start',
          },
          tooltipRenderer: ({ record, tip }) => {
            tip.title = record.name
            return `<dl>
            ${record.barcode ? `<dt>${'L{Scheduler.barcode}'}:</dt>
          <dd> ${record.barcode}</dd>` : '' }
          <dt>${'L{Scheduler.category}'}:</dt>
          <dd>${record.category}</dd>
          ${record.responsible ? `<dt>${'L{Scheduler.responsible}'}:</dt>
         <dd>${record.responsible}</dd>` : ''}
         ${record.serial_number ? `<dt>${'L{Scheduler.serial_number}'}:</dt>
          <dd>${record.serial_number}</dd>` : ''}
      </dl>`
          },
        },
        eventMenu: {
          items: {
            cutEvent: false,
            checkinItemAction: {
              text: 'L{Scheduler.checkin}',
              icon: 'fa-regular fa-arrow-down-to-arc',
              onItem({ source, eventRecord }) {
                self.checkinCheckout(eventRecord)
              },
              weight: 100,
            },
            checkoutItemAction: {
              text: 'L{Scheduler.checkout}',
              icon: 'fa-regular fa-arrow-right-from-arc',
              onItem({ source, eventRecord }) {
                self.checkinCheckout(eventRecord)
              },
              weight: 100,
            },
            splitItemAction: {
              text: 'L{Scheduler.split}',
              icon: 'fa-regular fa-scissors',
              onItem({ source, eventRecord }) {
                const currentEvent = eventRecord
                const newRecord = eventRecord.split()
                if (currentEvent.startDate < Date.now()) {
                  const startDate = newRecord.startDate
                  const currentDate = new Date()
                  startDate.setDate(currentDate.getDate() + 1)
                }
                const startDate = new Date(newRecord.startDate)
                const endDate = new Date(startDate.setDate(startDate.getDate() - 1))
                self.updateRecord(currentEvent.resourceId, currentEvent, currentEvent.resourceId, endDate)
                setTimeout(() => {
                  self.createRecord(currentEvent.resourceId, newRecord)
                  source.eventStore.remove(newRecord)
                }, 1000)
              },
              weight: 110,
            },
            editEvent: {
              weight: 400,
            },
            deleteEvent: {
              weight: 500,
            },
          },
          processItems({ eventRecord, items }) {
            if (!eventRecord.canDelete) {
              items.deleteEvent.disabled = true
            }
            if (!eventRecord.canEdit) {
              items.editEvent.disabled = true
            }
            if (eventRecord.readOnly || eventRecord.endDate < Date.now()) {
              items.splitItemAction.disabled = true
            }
            if (eventRecord.readOnly || eventRecord.scheduled) {
              items.checkinItemAction.hidden = true
            }
            if (eventRecord.readOnly || eventRecord.checked_out) {
              items.checkoutItemAction.hidden = true
              items.deleteEvent.disabled = true
            }
            if (eventRecord.readOnly) {
              items.copyEvent.disabled = true
              items.deleteEvent.disabled = true
            }
          },
        },
        stripe: true,
        timeRanges: {
          showCurrentTimeLine: true,
          showHeaderElements: true,
          enableResizing: true,
        },
        eventFilter: false,
        eventTooltip: {
          align: 'l-r',
          header: {
            titleAlign: 'start',
          },
          onBeforeShow({ source: tooltip }) {
            tooltip.title = StringHelper.encodeHtml(tooltip.eventRecord.resource.name +
                            ' ' + tooltip.eventRecord.status)
          },
          template: ({ eventRecord }) => window.$.ajax({
            url: '/items/'+eventRecord.resourceId+'/checkoutable_items/'+eventRecord.id,
            type: 'GET',
          }).then((result) =>
                            `<dl>
                                <dt>${'L{Scheduler.assignedTo}'}:</dt>
                                <dd> ${result.checkoutable}</dd>
                                <dt>${'L{Scheduler.time}'}:</dt>
                                <dd>
                                  ${result.startDate} - ${result.endDate}
                                </dd>
                                ${result.remarks ? `<dt>${'L{Scheduler.remarks}'}:</dt><dd>
                                ${StringHelper.encodeHtml(result.remarks)}</dd>` : ''}
                                ${result.scheduled_by ? `<dt>${'L{Scheduler.scheduled_by}'}:</dt><dd>
                                ${StringHelper.encodeHtml(result.scheduled_by)}</dd>` : ''}
                            </dl>`),
        },
        eventResize: {
          validatorFn(e) {
            const valid = e.edge == 'left' ? e.startDate >= Date.now() : e.endDate > Date.now()
            return {
              valid: valid,
              message: valid ? '' : 'L{Scheduler.dragValidation}',
            }
          },
        },
      },
      listeners: {
        beforeDragCreateFinalize(e) {
          const eventRecord = e.context.resourceRecord.data
          const startDate = e.context.startDate
          const endDate = e.context.endDate
          self.newRecord(startDate, endDate, eventRecord.id)
        },
        eventDrop(event) {
          const currentResource = event.resourceRecord.originalData
          const eventRecord = event.eventRecords[0].data
          self.updateRecord(currentResource.id, eventRecord, event.context.newResource.id)
        },
        eventResizeEnd({ eventRecord }) {
          const updatedRecord = eventRecord.data
          self.updateRecord(updatedRecord.resourceId, updatedRecord)
          return false
        },
        beforePaste({ source, records, date, resourceRecord }) {
          const originalRecord = records[0].originalData
          const endDate = new Date(date)
          const date1 = new Date(originalRecord.startDate)
          const date2 = new Date(originalRecord.endDate)
          const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10)
          endDate.setDate(endDate.getDate() + diffDays)
          const newRecord = {
            startDate: date, endDate: endDate,
            checkoutable_type: originalRecord.checkoutable_type,
            name: originalRecord.name_cached,
            checkoutable_id: originalRecord.checkoutable_id, remarks: originalRecord.remarks,
          }
          self.createRecord(resourceRecord.id, newRecord)
          return false
        },
        beforeEventEdit({ eventRecord, resourceRecord, eventElement }) {
          if (isNaN(eventRecord.id)) {
            self.newRecord(eventRecord.startDate, eventRecord.endDate, eventRecord.resourceId)
            this.eventStore.remove(eventRecord.id)
          } else {
            const href = '/items/'+eventRecord.resourceId+'/scheduling/'+eventRecord.id+'/edit?scheduler_view=true'
            Turbo.visit(href, { frame: 'modal-component' })
          }
          return false
        },
        beforeEventDelete({ eventRecords, context }) {
          swal.fire({
            title: window.I18n.t('js.are_you_sure'),
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'abbrechen',
            icon: 'question',
            iconHtml: '<div class="tw-text-3xl">?</div>',
            customClass: SwalStyles,
          }).then((result) => {
            if (result.isConfirmed) {
              Rails.ajax({
                url: '/items/'+eventRecords[0].data.resourceId+'/scheduling/'+eventRecords[0].data.id,
                data: new URLSearchParams({ scheduler_view: true }).toString(),
                type: 'DELETE',
              })
              context.finalize(true)
            }
          })
          return false
        },
        eventMenuShow({ menu, eventRecord, resourceRecord }) {
          const checkoutMenuItem = menu.items.find((item) => item.ref === 'checkoutItemAction')
          if (checkoutMenuItem) {
            // Add or hide existing items here as needed
            fetch('/items/'+eventRecord.resourceId+'/scheduling/'+eventRecord.id+'/item_checked_out_by', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((response) => response.text())
              .then((result) => {
                const jsonObject = JSON.parse(result)
                if (!(jsonObject.is_checkoutable)) {
                  checkoutMenuItem.disabled = true
                  checkoutMenuItem.tooltip = 'L{Scheduler.checkin_item_first}'
                }
              })
          }
        },
      },
    })

    this.scheduler.on({
      visibleDateRangeChange(event) {
        const eventStore = self.scheduler.eventStore
        const currentdate = new Date()
        const last3months = new Date(currentdate.setMonth(currentdate.getMonth()-3))
        if (event.new.endDate < last3months) {
          const search = new URL(window.location.href).searchParams.get('search')
          Rails.ajax({
            url: '/items/scheduler.json?'+search,
            data: new URLSearchParams({ start_date: event.new.startDate }).toString(),
            type: 'GET',
            dataType: 'script',
            success: function(result) {
              if (result.events != null && result.events.length > 0) {
                eventStore.loadDataAsync(result.events)
              }
            },
          })
        }
      },
      buffer: 300,
    })
  }

  scrollRight(e) {
    e.preventDefault()
    this.scheduler.scrollLeft += 120
  }
  scrollLeft(e) {
    e.preventDefault()
    this.scheduler.scrollLeft -= 120
  }

  zoomIn(e) {
    e.preventDefault()
    this.scheduler.zoomIn()
  }

  zoomOut(e) {
    e.preventDefault()
    this.scheduler.zoomOut()
  }

  setLocale() {
    const locale = { 'de': 'De', 'en': 'En', 'fr': 'Fr', 'es': 'Es', 'de-CH': 'De', 'fr-CH': 'Fr' }
    return locale[this.localeValue]
  }

  getTemplate(record) {
    if (record.imageUrl != null) {
      return `<div class="b-resource-info" role="presentation">
      <img draggable="false" loading="lazy" class="b-resource-avatar
      b-resource-image" alt="${record.name}" src="${record.imageUrl}"
      role="presentation"><dl role="presentation"><dt role="presentation">
      <a href="/items/${record.id}" target="_blank">${record.name}</a>
      </dt><dd class="b-resource-meta" role="presentation">
      ${record.barcode ? `BC: ${record.barcode}` : ''}</dd>
      </dl></div>`
    } else {
      return `<div class="b-resource-info" role="presentation">
      <div class='text-white b-resource-avatar' style='background-color: gray;'>
      <em class="b-fa-lg fas b-fa-camera"></em></div><dl role="presentation">
      <dt role="presentation"><a href="/items/${record.id}" target="_blank">
      ${record.name}</a></dt><dd class="b-resource-meta" role="presentation">
      ${record.barcode ? `BC: ${record.barcode}` : ''}</dd></dl></div>`
    }
  }

  updateRecord(resourceId, eventRecord, resourceRecordId, endDate) {
    const formData = new FormData()
    formData.append('checkoutable_item[planned_check_out_at]', eventRecord.startDate)
    formData.append('checkoutable_item[planned_check_in_at]', endDate || eventRecord.endDate)
    formData.append('checkoutable_item[checkoutable_type]', eventRecord.checkoutable_type || this.CUSTOM_CHECKOUT)
    formData.append('checkoutable_item[checkoutable_id]', eventRecord.checkoutable_id)
    formData.append('checkoutable_item[remarks]', eventRecord.remarks)
    formData.append('checkoutable_item[item_id]', resourceRecordId || '')
    formData.append('scheduler_view', true)

    fetch('/items/'+resourceId+'/scheduling/'+eventRecord.id, {
      method: 'PATCH',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
  }

  updateEvent(event) {
    const decodedData = decodeURIComponent(escape(atob(event.detail.event)))
    const data = JSON.parse(decodedData)
    this.scheduler.eventStore.addAsync(data)
  }

  newRecord(startDate, endDate, resourceId) {
    const data = { scheduler_view: true, planned_check_out_at: startDate, planned_check_in_at: endDate }
    const queryString = Object.keys(data).map(function(key) {
      return key + '=' + data[key]
    }).join('&')
    const url = '/items/'+resourceId+'/scheduling/new?'+ queryString
    Turbo.visit(url, { frame: 'modal-component' })
  }

  createRecord(resourceId, eventRecord) {
    const formData = new FormData()
    formData.append('checkoutable_item[planned_check_out_at]', eventRecord.startDate)
    formData.append('checkoutable_item[planned_check_in_at]', eventRecord.endDate)
    formData.append('checkoutable_item[checkoutable_type]', eventRecord.checkoutable_type || this.CUSTOM_CHECKOUT)
    formData.append('checkoutable_item[name_cached]', eventRecord.name)
    formData.append('checkoutable_item[checkoutable_id]', eventRecord.checkoutable_id)
    formData.append('checkoutable_item[remarks]', eventRecord.remarks)
    formData.append('scheduler_view', true)

    fetch('/items/'+resourceId+'/scheduling/', {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
  }

  monthPreset() {
    const monthView = new ViewPreset({
      id: 'dayMonth',
      name: 'Month View',
      tickWidth: 40,
      tickHeight: 80,
      displayDateFormat: 'll LT',
      shiftUnit: 'month',
      shiftIncrement: 1,
      defaultSpan: 1,
      mainUnit: 'month',
      timeResolution: {
        unit: 'hour',
        increment: 1,
      },
      headers: [
        {
          unit: 'month',
          dateFormat: 'MMMM YYYY',
        },
        {
          unit: 'day',
          dateFormat: 'DD',
        },
      ],
    })
    return monthView
  }

  weekNumberPreset() {
    const weekNumberAndYearView = new ViewPreset({
      id: 'weekNumberAndYear',
      name: 'Year/week number',
      tickWidth: 30,
      tickHeight: 32,
      displayDateFormat: '{w.}W YYYY',
      shiftUnit: 'year',
      shiftIncrement: 1,
      defaultSpan: 24,
      mainHeaderLevel: 1,
      timeResolution: {
        unit: 'd',
        increment: 1,
      },
      headers: [
        {
          unit: 'y',
          increment: 1,
          dateFormat: 'YYYY',
        },
        {
          unit: 'w',
          increment: 1,
          dateFormat: 'WW',
        },
      ],
    })
    return weekNumberAndYearView
  }

  checkinCheckout(eventRecord) {
    const url = '/items/'+eventRecord.resourceId+'/scheduling/'+eventRecord.id+'/checkout_checkin?scheduler_view=true'
    Turbo.visit(url, { frame: 'items_modal' })
  }
}

