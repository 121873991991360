import { ScanProcessing } from '../library/scan_processing'
import { TicketItem } from '../library/ticket_item'
import SwalStyles from '../utils/swal_styles'

const ITEMS_NEW_PATH = '/items/new'

export class ScanResult {
  constructor(params) {
    this.useSameDate = params.useSameDate
    this.checkoutDocument = params.checkoutDocument
    this.scheduleCheckoutParams = params.scheduleCheckoutParams
    this.remainingItemCount = params.remainingItemCount
    this.nothingScannedTableNotice = params.nothingScannedTableNotice
    this.lastScanTableBody = params.lastScanTableBody
    this.screenNotification = params.screenNotification
    this.controllerCallback = params.controllerCallback
    this.forceCheckOut = params.forceCheckOut
  }

  configure(data) {
    this.processMethod = data.process_method
    this.forwardUrl = data.forward_url
    this.sourceItemId = data.source_item_id
    this.rootCategoryId = data.root_category_id
    this.stockItemSpecialCheckoutFormUrl = data.special_checkout_form_url
    this.scheduleCheckoutFormUrl = data.scheduled_checkout_form_url
    this.expiryDate = data.deadline_expiry_date
    this.responsibleUser = data.responsible_user_id
    this.barcodeSymbologyElement = data.barcode_symbology_input_element
    this.barcodeInputElement = document.getElementById(
      data.barcode_input_element,
    )
  }

  updateProcessMethod(newMethod) {
    this.processMethod = newMethod
    if (newMethod === 'checkout_scanner_assign_to_me') {
      this.setupAssignToMeCheckout()
    }
  }

  setupAssignToMeCheckout() {
    this.checkoutObjectResponse = {
      system_obj_type: 'Person',
      system_obj_id: window.PERSON_ID,
    }
    this.stockItemSpecialCheckoutFormUrl =
      '/items/:item_id/checkoutable_items/special_checkout_form?resource_type=' +
      'Person' +
      '&resource_id=' +
      window.PERSON_ID
    this.scheduleCheckoutFormUrl =
      '/items/:item_id/checkout_modal/' + 'Person' + '/' + window.PERSON_ID
  }

  updateGpsPosition(gpsPosition) {
    this.gpsPosition = gpsPosition
  }

  process(scanResult) {
    if (this.needsRedirect(scanResult)) {
      return (document.location.href = scanResult.text)
    }

    switch (this.processMethod) {
      case 'checkout_scanner':
        this.checkoutScanner(scanResult)
        break
      case 'checkout_scanner_assign_to_me':
        this.checkoutScanner(scanResult)
        break
      case 'return_scan_value':
        // returns the barcode value and does no further action
        // this is used on the item_edit page to connect / add a barcode to the item
        this.returnScanValue(scanResult)
        break
      case 'redirect':
        // this will redirect the user to the item / container (--> see ContainerModel)
        this.redirectToObject(scanResult)
        break
      case 'add_to_object':
        // used for adding twins to items
        this.addToObject(scanResult)
        break
      case 'checkout':
      case 'inventory_take':
        this.checkoutToResource(scanResult, this.forceCheckoutSwitch, false)
        break
      case 'inventory':
        this.inventoryScanning(scanResult)
        break
      case 'add_deadline_to_item':
        this.addDeadlineToItem(scanResult)
    }
  }

  async checkoutScanner(scanResult) {
    const processedResponse = await ScanProcessing.submitToUrl(
      scanResult,
      '/scan',
      {},
      this.gpsPosition,
    )
    if (processedResponse.status != 'ok') {
      this.screenNotification.show(processedResponse.error, 'warning')
      return
    }
    if (this.processMethod === 'checkout_scanner') {
      if (
        processedResponse.system_obj_type == 'Person' ||
        processedResponse.system_obj_type == 'Container'
      ) {
        this.checkoutObjectResponse = processedResponse
        this.stockItemSpecialCheckoutFormUrl =
          '/items/:item_id/checkoutable_items/special_checkout_form?resource_type=' +
          processedResponse.system_obj_type +
          '&resource_id=' +
          processedResponse.system_obj_id
        this.scheduleCheckoutFormUrl =
          '/items/:item_id/checkout_modal/' +
          processedResponse.system_obj_type +
          '/' +
          processedResponse.system_obj_id
        this.controllerCallback(processedResponse, 'set_checkoutable_resource')
        return
      }
    }
    if (this.checkoutObjectResponse) {
      const url =
        '/checkout_to_resource/' +
        this.checkoutObjectResponse.system_obj_type +
        '/' +
        this.checkoutObjectResponse.system_obj_id
      this.forwardUrl = document.location.origin + url

      const response = await ScanProcessing.ajaxPostDataToUrl(
        processedResponse.barcode,
        processedResponse.symbology,
        processedResponse.type,
        url,
        { skip_reassignment_validation: !this.forceCheckOut },
        this.gpsPosition,
      )

      if (this.processCheckoutToResourceResponse(scanResult, response)) {
        this.screenNotification.show(
          response.message,
          'success',
          response.checked_out_by,
        )
        this.controllerCallback(response, 'success_checkout_to_resource')
      }
    } else {
      this.controllerCallback(
        processedResponse,
        'no_checkoutable_resource_found',
      )
    }
  }

  needsRedirect(scanResult) {
    if (this.processMethod === 'checkout_scanner') {
      return false
    }
    const regexPattern = /checkoutable_type=(Container|Person)/
    if (regexPattern.test(scanResult.text) && scanResult.symbology === 'qr') {
      return true
    }

    if (/persons\/\d+/.test(scanResult.text) && scanResult.symbology === 'qr') {
      return true
    }
    return false
  }

  // return the barcode scan value.
  async returnScanValue(result) {
    const processedResponse = await ScanProcessing.submitToUrl(
      result,
      '/scan',
      {},
      this.gpsPosition,
    )
    if (
      processedResponse.status != 'failed' &&
      processedResponse.status != 'not_found' &&
      this.barcodeInputElement.value != processedResponse.barcode
    ) {
      this.tearDown()
      swal
        .fire({
          title: window.I18n.t('js.barcode_already_in_use'),
          html:
            'Barcode <b>' +
            processedResponse.barcode +
            '</b> => ' +
            processedResponse.obj_name +
            ' (' +
            processedResponse.obj_type +
            ')',
          icon: 'warning',
          confirmButtonText: window.I18n.t('js.go_to_object'),
          cancelButtonText: window.I18n.t('js.close'),
          showCancelButton: true,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.location.href = processedResponse.url
          }
        })
    } else {
      this.barcodeInputElement.value = processedResponse.barcode

      // insert hidden_field for symbology attribute on barcode
      if (this.barcodeSymbologyElement) {
        const newInputEl = this.hiddenFieldCreatorForBarcodeRecord(
          'symbology',
          result.symbology,
        )
        this.barcodeInputElement.parentNode.insertBefore(
          newInputEl,
          this.barcodeInputElement,
        )
      }

      // insert hidden_field for GPS position attribute on barcode
      if (this.gpsPosition) {
        const pos = this.gpsPosition
        const newLatInputEl = this.hiddenFieldCreatorForRecord(
          this.gpsFieldName('latitude'),
          pos['latitude'],
        )
        this.barcodeInputElement.parentNode.insertBefore(
          newLatInputEl,
          this.barcodeInputElement,
        )
        const newLonInputEl = this.hiddenFieldCreatorForRecord(
          this.gpsFieldName('longitude'),
          pos['longitude'],
        )
        this.barcodeInputElement.parentNode.insertBefore(
          newLonInputEl,
          this.barcodeInputElement,
        )
        const newAccInputEl = this.hiddenFieldCreatorForRecord(
          this.gpsFieldName('accuracy'),
          pos['accuracy'],
        )
        this.barcodeInputElement.parentNode.insertBefore(
          newAccInputEl,
          this.barcodeInputElement,
        )
      }
      const newnewLastScanInputElInputEl = this.hiddenFieldCreatorForRecord(
        'item[update_last_scan]',
        true,
      )
      this.barcodeInputElement.parentNode.insertBefore(
        newnewLastScanInputElInputEl,
        this.barcodeInputElement,
      )

      // if user scanned a Direct Access Token (response contains auth_token)
      // insert hidden_field for auth_token attribute on barcode
      if (processedResponse.datToken) {
        const newInputEl = this.hiddenFieldCreatorForBarcodeRecord(
          'auth_token',
          processedResponse.datToken,
        )
        this.barcodeInputElement.parentNode.insertBefore(
          newInputEl,
          this.barcodeInputElement,
        )

        // unhide indicator for showing user that barcode has adirect access token attached
        const elDatIndicator = document.getElementById(
          'barcode-indicator-for-direct-access',
        )
        if (elDatIndicator != null) {
          elDatIndicator.classList.remove('d-none')
        }
      }
      this.tearDown()
    }
  }

  async redirectToObject(result, forceActive = false) {
    const processedResponse = await ScanProcessing.processScanAndQueryDb(
      result,
      this.gpsPosition,
      this.processMethod,
      forceActive,
    )
    if (processedResponse.status == 'ok') {
      // Happy case. Redirect to received URL
      document.location.href = processedResponse.url
    } else if (
      processedResponse.hasOwnProperty('error') &&
      processedResponse.error == 'barcode_invalid_url'
    ) {
      // Show only a small error and continue scanning
      this.screenNotification.notify('barcode_not_valid', 'error')
    } else if (
      processedResponse.hasOwnProperty('error') &&
      processedResponse.error == 'not_found'
    ) {
      // Provide more options for user on what to do next.
      swal
        .fire({
          title:
            window.I18n.t('js.not_found') + ': ' + processedResponse.barcode,
          text: window.I18n.t('js.scanned_code_not_assigned'),
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: window.I18n.t('js.scan_again'),
          confirmButtonText: window.I18n.t('js.create_new'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            const href = ITEMS_NEW_PATH + '?bc=' + processedResponse.barcode

            Turbo.visit(href, { frame: 'modal-component' })
          }
        })
    } else if (processedResponse.status == 'failed') {
      this.missingStatusNotification(processedResponse)
    }
  }

  // Used for adding twins to items
  async addToObject(scanResult) {
    const processedResponse = await ScanProcessing.submitToUrl(
      scanResult,
      this.forwardUrl,
      {},
      this.gpsPosition,
    )

    if (processedResponse.hasOwnProperty('error')) {
      if (
        processedResponse.error == 'barcode_already_in_use_with_item' ||
        processedResponse.error == 'move_barcode_from_another_blueprint'
      ) {
        return this.showConvertTransferError(processedResponse)
      }
      if (processedResponse.error == 'cannot_convert_stock_item_to_twin') {
        return this.showStockItemConvertError(processedResponse)
      }
      this.screenNotification.show(processedResponse.error, 'error')
    } else {
      // SUCCESS CASE
      this.screenNotification.show(
        'barcode_added_to_list',
        'success',
        processedResponse.barcode,
      )

      // Simulate a click on the Refresh Twins Table icon
      const elTwinsTab = document.getElementById(
        'blueprint_twins_refresh_table',
      )
      if (elTwinsTab != null) {
        elTwinsTab.click()
      }
    }
  }

  async checkoutToResource(
    scanResult,
    forceCheckOut = false,
    forceActive = false,
  ) {
    const processedResponse = await ScanProcessing.submitToUrl(
      scanResult,
      this.forwardUrl,
      {
        force_check_out: forceCheckOut,
        force_active: forceActive,
        skip_reassignment_validation: !forceCheckOut,
        use_same_checkout_params: this.useSameDate,
        check_out_documents: this.checkoutDocument,
        source_item_id: this.sourceItemId,
        root_category_id: this.rootCategoryId,
        ...this.scheduleCheckoutParams,
      },
      this.gpsPosition,
    )

    if (this.processCheckoutToResourceResponse(scanResult, processedResponse)) {
      this.controllerCallback(processedResponse, 'success_checkout_to_resource')
      if (!this.checkoutObjectResponse) {
        this.screenNotification.show(
          processedResponse.message,
          'success',
          processedResponse.checked_out_by ||
            processedResponse.status_changed_by_name ||
            processedResponse.resource_name,
        )
      }
    }
  }

  processCheckoutToResourceResponse(scanResult, processedResponse) {
    if (processedResponse.validation_error) {
      return this.validationCheckoutMessage(scanResult, processedResponse)
    }
    if (this.remainingItemCount && processedResponse.total_count_message) {
      this.remainingItemCount.textContent =
        processedResponse.total_count_message
    }
    if (processedResponse.hasOwnProperty('error')) {
      return this.showCheckoutToResourceError(processedResponse.error, 'error')
    }

    if (processedResponse.origin == 'activity') {
      return TicketItem.add(processedResponse, this.screenNotification)
    }

    if (
      processedResponse.message == 'barcode_assigned_to_other_resource_location'
    ) {
      return this.barcodeAssignedToOtherResourceLocation(
        scanResult,
        processedResponse,
      )
    }

    if (processedResponse.message == 'barcode_not_assigned_to_resource') {
      return this.barcodeNotAssignedToResource(scanResult, processedResponse)
    }

    if (processedResponse.message == 'barcode_assigned_or_expired_deadline') {
      return this.barcodeAssignedOrExpiredDeadline(
        scanResult,
        processedResponse,
      )
    }

    if (processedResponse.message == 'barcode_assigned_or_expired_ticket') {
      return this.barcodeAssignedOrExpiredTicket(scanResult, processedResponse)
    }

    if (
      processedResponse.message == 'unauthorize_to_checkout_during_stocktaking'
    ) {
      return this.unauthorizeToCheckoutDuringStocktaking(
        scanResult,
        processedResponse,
      )
    }

    if (processedResponse.message == 'stock_item_checkout') {
      return this.stockItemCheckoutProcess(processedResponse)
    }

    if (processedResponse.message == 'schedulable_checkout') {
      return this.schedulableCheckoutProcess(processedResponse)
    }

    if (
      processedResponse.message == 'barcode_already_checked_out_other_resource'
    ) {
      return this.showAlreadyCheckedOutError(processedResponse, scanResult)
    }

    if (
      processedResponse.message == 'barcode_already_confirmed_same_resource'
    ) {
      return true
      // return this.screenNotification.show(processedResponse.message, 'success', processedResponse.resource_name)
    }

    if (processedResponse.message == 'missing_status') {
      return this.missingStatusNotification(processedResponse, scanResult)
    }
    return true
  }

  async addDeadlineToItem(scanResult) {
    const processedResponse = await ScanProcessing.submitToUrl(
      scanResult,
      this.forwardUrl,
      {
        expiry_date: this.expiryDate,
        responsible_id: this.responsibleUser,
      },
      this.gpsPosition,
    )

    if (processedResponse.hasOwnProperty('error')) {
      return this.showCheckoutToResourceError(processedResponse.error, 'error')
    }

    // SUCCESS CASE
    this.screenNotification.show(
      processedResponse.message,
      'success',
      processedResponse.checked_out_by,
    )
  }

  async inventoryScanning(scanResult) {
    const processedResponse = await ScanProcessing.processScanAndQueryDb(
      scanResult,
      this.gpsPosition,
      this.processMethod,
    )
    if (processedResponse.status == 'ok') {
      this.screenNotification.notify(
        processedResponse.obj_name +
          '<br>[BC: ' +
          processedResponse.barcode +
          ']',
        'success',
      )
      this.updateLastScanTable(processedResponse)
    } else if (
      processedResponse.hasOwnProperty('error') &&
      processedResponse.error == 'not_found'
    ) {
      // Show only a small error and continue scanning
      this.screenNotification.notify('barcode_not_assigned', 'warning')
    } else {
      this.screenNotification.notify('barcode_not_valid', 'error')
    }
  }

  hiddenFieldCreatorForBarcodeRecord(nameAttribute, inputValue) {
    const newInputEl = document.createElement('input')
    newInputEl.setAttribute('type', 'hidden')
    // to submit to the same barcode record, we take the name attribute of the barcode_string input field
    // and change name to auth_token
    newInputEl.setAttribute(
      'name',
      this.barcodeInputElement.name.replace(
        /\[\w*\]$/,
        '[' + nameAttribute + ']',
      ),
    )
    newInputEl.value = inputValue
    return newInputEl
  }

  hiddenFieldCreatorForRecord(nameAttribute, inputValue) {
    const newInputEl = document.createElement('input')
    newInputEl.setAttribute('type', 'hidden')
    newInputEl.setAttribute('name', nameAttribute)
    newInputEl.value = inputValue
    return newInputEl
  }

  showAlreadyCheckedOutError(processedResponse, scanResult) {
    swal
      .fire({
        title: window.I18n.t('js.already_checked_out'),
        html:
          '<b>' +
          processedResponse.item_name +
          '</b> (BC: ' +
          processedResponse.barcode +
          ') ' +
          window.I18n.t('js.already_checked_out') +
          ' :<br>' +
          processedResponse.checked_out_by +
          '<br><br><b>' +
          window.I18n.t('js.already_checked_out_long') +
          '</b>',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: window.I18n.t('js.close'),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.checkoutToResource(scanResult, true)
        }
      })
  }

  showConvertTransferError(processedResponse) {
    swal
      .fire({
        title: processedResponse.title,
        html: processedResponse.html,
        icon: 'warning',
        reverseButtons: true,
        confirmButtonText: processedResponse.buttonText,
        cancelButtonText: window.I18n.t('js.cancel'),
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          Rails.ajax({
            url: processedResponse.url,
            type: 'post',
            dataType: 'script',
          })
        }
      })
  }

  showStockItemConvertError(processedResponse) {
    swal.fire({
      title: processedResponse.title,
      icon: 'warning',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: window.I18n.t('js.cancel'),
      iconHtml: '<div class="tw-text-3xl">!</div>',
      customClass: SwalStyles,
    })
  }

  missingStatusNotification(processedResponse, scanResult) {
    swal
      .fire({
        title: window.I18n.t('js.missing_status'),
        html:
          '<b>' +
          processedResponse.item_name +
          '</b>' +
          ' ' +
          window.I18n.t('js.item_is_missing_long'),
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: window.I18n.t('js.cancel'),
        reverseButtons: true,
      })
      .then((result1) => {
        if (result1.isConfirmed) {
          this.checkoutToResource(scanResult, false, true)
        }
      })
  }

  // only used for the inventory scanning. Update the table below the scan video stream.
  // Displays the scanned item.
  updateLastScanTable(result) {
    if (this.nothingScannedTableNotice) {
      this.nothingScannedTableNotice.remove()
    }
    const today = new Date()
    const newNode =
      '<tr><td>' +
      today.getHours() +
      ':' +
      (today.getMinutes() < 10 ? '0' : '') +
      today.getMinutes() +
      ':' +
      (today.getSeconds() < 10 ? '0' : '') +
      today.getSeconds() +
      '</td><td>' +
      result.obj_name +
      '</td><td>' +
      result.barcode +
      '</td></tr>'
    $(this.lastScanTableBody).prepend(newNode)
  }

  showCheckoutToResourceError(error) {
    if (!this.checkoutObjectResponse) {
      this.screenNotification.show(error, 'error')
    }
  }

  stockItemCheckoutProcess(processedResponse) {
    let url = this.stockItemSpecialCheckoutFormUrl
    url = url.replace(':item_id', processedResponse.item_id)
    window.Rails.ajax({
      url: url,
      dataType: 'html',
      type: 'GET',
      accept: 'text/html',
      success: (result) => {
        const html = result.getElementsByTagName('form')[0]
        document.getElementById('stock-checkout-modal-content').innerHTML =
          html.outerHTML
        $('#stock-checkout-modal').modal()
      },
    })
  }

  schedulableCheckoutProcess(processedResponse) {
    let url = this.scheduleCheckoutFormUrl
    url = url.replace(':item_id', processedResponse.item_id)
    window.Rails.ajax({
      url: url,
      data: new URLSearchParams({
        from_scan: !this.checkoutObjectResponse,
      }),
      dataType: 'html',
      type: 'GET',
      accept: 'text/html',
      success: (result) => {
        const html = result.getElementsByTagName('form')[0]
        document.getElementById('resource_checkout_modal_content').innerHTML =
          html.outerHTML
        $('#resource-checkout-modal').modal()
      },
    })
  }

  barcodeAssignedToOtherResourceLocation(_scanResult, processedResponse) {
    swal.fire({
      title: `${window.I18n.t('js.not_belongs_to_org_unit')} ${processedResponse.location_name}`,
      html: `<b> ${processedResponse.item_name} </b> (BC:  ${processedResponse.barcode})
        ${window.I18n.t('js.not_assign_to_current_org_unit')} ${processedResponse.location_name}`,
      icon: 'error',
      showCancelButton: true,
      cancelButtonText: window.I18n.t('js.close'),
      showConfirmButton: false,
      customClass: SwalStyles,
    })
  }

  barcodeNotAssignedToResource(scanResult, processedResponse) {
    swal
      .fire({
        title: window.I18n.t('js.not_assigned_to_resource'),
        html:
          '<b>' +
          processedResponse.item_name +
          ' </b> ' +
          window.I18n.t('js.item_not_assigned') +
          ' <b> ' +
          processedResponse.resource_name +
          '</b>' +
          window.I18n.t('js.want_to_assign'),
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: window.I18n.t('js.close'),
        reverseButtons: true,
        confirmButtonText: window.I18n.t('js.assign'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.checkoutToResource(
            scanResult,
            true,
            processedResponse.force_active,
          )
        }
      })
  }
  barcodeAssignedOrExpiredTicket(scanResult, processedResponse) {
    const ticketTitle = window.I18n.t(
      'inventory.checkoutable_items.errors.ticket_expired_title',
    )
    swal
      .fire({
        title: ticketTitle,
        html:
          '<b>' +
          window.I18n.t('js.the_ticket') +
          ' </b> ' +
          processedResponse.ticket_name +
          ' <b> ' +
          window.I18n.t('js.is_overdue') +
          '</b>' +
          window.I18n.t('js.please_confirm'),
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: window.I18n.t('js.close'),
        reverseButtons: true,
        confirmButtonText: window.I18n.t('js.assign'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.checkoutToResource(
            scanResult,
            true,
            processedResponse.force_active,
          )
        }
      })
  }

  barcodeAssignedOrExpiredDeadline(scanResult, processedResponse) {
    const deadlineTitle = window.I18n.t(
      'inventory.checkoutable_items.errors.deadline_expired_title',
    )
    swal
      .fire({
        title: `${window.I18n.t('js.not_assigned_to_resource')} / ${deadlineTitle}`,
        html:
          '<b>' +
          processedResponse.item_name +
          ' </b> ' +
          window.I18n.t('js.item_not_assigned') +
          ' <b> ' +
          processedResponse.resource_name +
          '</b>' +
          window.I18n.t('js.want_to_assign'),
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: window.I18n.t('js.close'),
        reverseButtons: true,
        confirmButtonText: window.I18n.t('js.assign'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.checkoutToResource(
            scanResult,
            true,
            processedResponse.force_active,
          )
        }
      })
  }

  unauthorizeToCheckoutDuringStocktaking(_scanResult, processedResponse) {
    swal.fire({
      title: window.I18n.t('js.unauthorized_action'),
      html:
        '<b>' +
        processedResponse.item_name +
        ' </b> ' +
        window.I18n.t('js.unauthorized_action_message'),
      icon: 'error',
      showCancelButton: true,
      reverseButtons: true,
      showConfirmButton: false,
      cancelButtonText: window.I18n.t('js.close'),
      customClass: SwalStyles,
    })
  }

  validationCheckoutMessage(scanResult, processedResponse) {
    swal
      .fire({
        title: processedResponse.title,
        html:
          processedResponse.message +
          '<br><br>' +
          '<b>' +
          window.I18n.t('js.still_want_assign') +
          '</b>',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: window.I18n.t('js.assign'),
        cancelButtonText: window.I18n.t('js.close'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.checkoutToResource(
            scanResult,
            true,
            processedResponse.force_active,
          )
        }
      })
  }

  tearDown(skipHideModal) {
    if (!skipHideModal) {
      $('#scanner').modal('hide')
    }
    const tabPane = document.getElementById('tab_pane_container')
    if (tabPane) {
      const src = tabPane.src
      tabPane.src = null
      tabPane.src = src
      return
    }
    const element = $('.reload-after-scann.active').parent()[0]
    if (element) {
      if (element.dataset.href) {
        Turbo.visit(element.dataset.href, {
          frame: element.dataset.turboFrame,
        })
      } else {
        element.dispatchEvent(new Event('click', { bubbles: true }))
      }
    }
  }

  gpsFieldName(name) {
    return `item[gps_position][${name}]`
  }
}
