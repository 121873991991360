import { Controller } from '@hotwired/stimulus'
import { productFruits } from 'product-fruits'

// Connects to data-controller="product-fruits"
export default class extends Controller {
  static values = {
    hmac: String,
    username: String,
    firstname: String,
    lastname: String,
    role: String,
    workspaceCode: String,
    entityStatus: String,
    signUpAt: String,
    disabled: Boolean,
    locale: { type: String, default: 'en' },
  }
  connect() {
    let locale = this.localeValue
    if (locale.match(/-/)) {
      locale = locale.split('-')[0]
    }
    productFruits.init(this.workspaceCodeValue, locale, {
      username: this.usernameValue,
      email: this.usernameValue,
      firstname: this.firstnameValue,
      lastname: this.lastnameValue,
      role: this.roleValue,
      signUpAt: this.signUpAtValue,
      hmac: {
        hash: this.hmacValue,
      },
      props: {
        entityStatus: this.disabledValue ? null : this.entityStatusValue,
      },
    })

    const customLauncher = document.getElementById('newsfeed-launcher')
    customLauncher.classList.remove('d-none')
    window.productFruitsReady = function() {
      window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
        if (data.count > 0) {
          $(customLauncher).find('.badge-count').text(data.count).css('display', 'flex')
        } else {
          $(customLauncher).find('.badge-count').text(data.count).css('display', 'none')
        }
      })
      window.productFruits.api.announcementsV2.attachNewsWidgetToElement(customLauncher)
    }
  }
}
