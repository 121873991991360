import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'query',
    'results',
    'name',
    'collection',
    'searchId',
    'fancy',
  ]
  static values = {
    selected: Array,
    field: String,
    endpoint: String,
    multi: Boolean,
    source: Number,
    createTags: Boolean,
    templateId: String,
  }

  connect() {
    this.resultsList = this.resultsTarget.getElementsByTagName('li')
    this.totalResults = this.resultsList.length
    this.currentIndex = -1
    this.inputValuePresent = false
    this.tags = new Map()
    this.setHiddenFieldName()
    this.selectedTags()
  }

  update(event) {
    const text = event.target.value

    this.sendRequest(text)
  }

  filterUpdate(_event) {
    this.sendRequest()
  }

  sendRequest(text) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.requestInfo(text)
    }, 400)
  }

  checkInput(_event) {
    this.requestInfo()
  }

  requestInfo(text) {
    if (!this.endpointValue || this.endpointValue.length == 0) return

    const locationId = document.getElementById('ticket_location_id').value
    const selectedIds = Array.from(this.tags.keys())
    const selectedIdsParam = selectedIds
      .map((id) => `selected_ids[]=${id}`)
      .join('&')
    const href =
      `${this.endpointValue}?query=${text}&search_id=${this.searchIdTarget.value}` +
      `&source=${this.sourceValue}&${selectedIdsParam}&location_id=${locationId}`
    fetch(href, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': this.authenticityToken(),
      },
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
        setTimeout(() => {
          this.showResultsIfAny()
        }, 100)
      })
  }

  hideResults(_event) {
    this.resultsTarget.hidden = true
  }

  toggleResults(event) {
    event.stopPropagation()

    this.resultsTarget.hidden = !this.resultsTarget.hidden
    if (!this.resultsTarget.hidden) {
      this.requestInfo('')
      // do not request results if already results present
      // this.resultsList = this.resultsTarget.getElementsByTagName('li')
      // this.totalResults = this.resultsList.length
      // if (this.totalResults == 0) {
      // this.requestInfo('')
      // }
    }
  }

  performAction(event) {
    // Some other library is capturing the key down events
    // we can't use keydown.down or keydown.up in stimulus
    switch (event.key) {
      case 'ArrowDown':
        this.downResult(event)
        break
      case 'ArrowUp':
        this.upResult(event)
        break
      case 'Enter':
        this.selectResult(event)
        break
      case 'Escape':
        this.toggleResults(event)
        break
    }
  }

  upResult(event) {
    event.stopPropagation()
    if (this.totalResults < 1) {
      return
    }

    if (document.activeElement == this.nameTarget) {
      this.focusResults()
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1
        this.resultsList[this.currentIndex].focus()
        this.resultsList[this.currentIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    }
  }

  downResult(event) {
    if (event.target.value.length > 0) {
      this.inputValuePresent = true
    }
    // Some other library is capturing the arrow down key
    // we can't use keydown.down or keydown.up in stimulus
    if (event.key !== 'ArrowDown') {
      return
    }
    event.stopPropagation()
    if (document.activeElement == this.nameTarget) {
      this.focusResults()
    } else {
      if (this.currentIndex < this.totalResults - 1) {
        this.currentIndex += 1
        this.resultsList[this.currentIndex].focus()
        this.resultsList[this.currentIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    }
  }

  focusResults() {
    this.resultsTarget.hidden = false
    this.resultsList = this.resultsTarget.getElementsByTagName('li')
    this.totalResults = this.resultsList.length
    this.currentIndex = 0
    if (this.totalResults > 0) {
      this.resultsList[this.currentIndex].focus()
      this.resultsList[this.currentIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }

  showResultsIfAny() {
    this.resultsList = this.resultsTarget.getElementsByTagName('li')
    if (this.resultsList.length > 0) {
      this.resultsTarget.hidden = false
    }
  }

  selectResult(event) {
    event.target
    const item = event.target.closest('li')
    const id = parseInt(item.dataset.id)
    this.addTag(id, item.dataset)
    this.resultsTarget.hidden = true
  }

  clearSelection(_event) {
    this.nameTarget.value = ''
    this.resultsTarget.hidden = true
  }

  validateInput(event) {
    const inputText = event.target.value
    let name = inputText.trim()

    if (event.key === 'Backspace' && !this.inputValuePresent) {
      this.removeLastTag()
    } else {
      if (this.createTagsValue && name && name.at(-1) === ',') {
        name = name.slice(0, -1)
        name = name.trim()
        this.addTag(name.toLowerCase(), { name: name })
        this.nameTarget.value = ''
      }
    }
  }

  exitInput(event) {
    if (!this.createTagsValue) return

    const key = event.target.value
    const name = key.trim()
    if (name) {
      this.addTag(name.toLowerCase(), { name: name })
      this.nameTarget.value = ''
    }
  }

  addTag(id, content) {
    if (content && content.name && content.name.trim().length > 0) {
      if (!this.multiValue) {
        this.tags.clear()
      }

      this.refreshTags(id, content)
    }
  }

  refreshTags(id, content) {
    this.tags.set(id, content)
    this.dispatchSelectedEvent(content)
    this.createTags(Array.from(this.tags))
  }

  createTags(tags) {
    this.fancyTarget.innerHTML = ''
    if (tags.length == 0) {
      this.fancyTarget.hidden = true
      this.nameTarget.classList.add('tw-w-full')
      this.nameTarget.classList.remove('tw-w-16')
      return
    }
    this.fancyTarget.hidden = false
    this.nameTarget.classList.remove('tw-w-full')
    this.nameTarget.classList.add('tw-w-16')
    tags.forEach((tag) => this.spanTag(tag[1]))
    this.nameTarget.value = ''
  }

  removeLastTag() {
    if (this.tags.size == 0) return

    const lastElement = Array.from(this.tags.values()).pop()

    this.tags.delete(lastElement.id)
    this.createTags(Array.from(this.tags))
  }

  removeTag(event) {
    event.stopPropagation()

    const elementId = parseInt(event.target.dataset.id)
    this.tags.forEach((element, key) => {
      const intKey = parseInt(key)
      if (intKey === elementId && !element.readonly) {
        this.tags.delete(key)
        this.dispatchRemovedEvent(element)
      }
    })
    this.createTags(Array.from(this.tags))
  }

  spanTag(content) {
    if (!content) return

    const templateName = `tag-template-${this.templateIdValue}`
    const template = document.getElementById(templateName)
    const newTemplate = template.content.cloneNode(true)
    newTemplate.appendChild(this.hiddenField(content.id))
    this.replacePlaceholders(content, newTemplate)
    console.warn(newTemplate.firstElementChild.innerHTML)
    this.fancyTarget.appendChild(newTemplate)
  }

  replacePlaceholders(content, newTemplate) {
    for (const [key, value] of Object.entries(content)) {
      newTemplate.firstElementChild.innerHTML =
        newTemplate.firstElementChild.innerHTML.replace(`{{${key}}}`, value)
    }
    console.log(newTemplate.firstElementChild.innerHTML)
  }

  hiddenField(id) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', this.fieldName)
    input.setAttribute('value', id)

    return input
  }

  setHiddenFieldName() {
    if (this.multiValue) {
      this.fieldName = this.fieldValue + '[]'
    } else {
      this.fieldName = this.fieldValue
    }
  }

  selectedTags() {
    if (this.selectedValue.length > 0) {
      this.selectedValue.forEach((tag) => {
        if (tag.id) {
          this.tags.set(tag.id, tag)
        }
      })
      this.createTags(Array.from(this.tags))
    } else {
      this.createTags([])
    }
  }

  authenticityToken() {
    return document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
  }

  dispatchSelectedEvent(data) {
    this.element.dispatchEvent(
      new CustomEvent('tag-selected', {
        detail: { data: data },
        bubbles: true,
      }),
    )
  }

  dispatchRemovedEvent(data) {
    this.element.dispatchEvent(
      new CustomEvent('tag-removed', {
        detail: { data: data },
        bubbles: true,
      }),
    )
  }
}
