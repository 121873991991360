import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/l10n/fr.js'
import RangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

const flatpickrOneMonthWith = 308

require('flatpickr/dist/flatpickr.min.css')
require('flatpickr/dist/plugins/monthSelect/style.css')

export default class extends Controller {
    static targets = ['input', 'positionElement'];
    static values = {
      range: Boolean,
      preloadDate: String,
      inline: Boolean,
      multipleMonths: Boolean,
      locale: String,
    }

    connect() {
      this.flatpickrElement = (this.hasInputTarget ? this.inputTarget : this.element)

      // Check if an inputTarget was defined. If defined, launch calendar on inputTarget
      // else launch directly on the element
      this.flatpickr = flatpickr(this.flatpickrElement, this.setLoadOptions())
    }

    setLoadOptions() {
      const options = {}
      options['plugins'] = [new MonthSelectPlugin({
        shorthand: true, // defaults to false
        dateFormat: 'Y-m-d', // defaults to "F Y"
        altFormat: 'M Y', // defaults to "F Y"
      })]


      if (this.hasLocaleValue) {
        let locale = this.localeValue || 'de'
        if (locale.match(/-/)) {
          locale = locale.split('-')[0]
        }
        options['locale'] = locale
      }
      if (this.hasPositionElementTarget) {
        options['positionElement'] = this.positionElementTarget
      }
      if (this.inlineValue == true) {
        options['inline'] = true
      }
      if (this.hasMultipleMonthsValue) {
        options['showMonths'] = this.maxMultipleMonth()
      }
      if (this.rangeValue === true) {
        options['mode'] = 'range'
      }
      // add the plugin range for range selecting on two inputs
      if (options['mode'] == 'range' && this.inputTargets.length == 2) {
        options['plugins'].push(new RangePlugin({ input: this.inputTargets[1] }))
      }

      if (this.flatpickrElement.tagName == 'INPUT' && this.flatpickrElement.getAttribute('type') != 'hidden') {
        options['allowInput'] = true // flatpickr default is false
        options['altInput'] = true // flatpickr default is false
      }

      return options
    }

    open() {
      this.flatpickr.open()
    }

    clear() {
      this.flatpickr.clear()
    }

    updateShowMonth() {
      const numMonth = this.maxMultipleMonth() || 1

      if (typeof this.flatpickr !== 'undefined' &&
            typeof this.flatpickr.config.showMonths !== 'undefined' &&
            numMonth != this.flatpickr.config.showMonths) {
        const newWidth = numMonth * flatpickrOneMonthWith + 'px'
        this.flatpickr.set('showMonths', numMonth)
        this.flatpickr.redraw()
        document.getElementsByClassName('flatpickr-calendar')[0].style.width = newWidth
        document.getElementsByClassName('flatpickr-rContainer')[0].style.width = newWidth
      }
    }

    maxMultipleMonth() {
      const width = this.element.offsetWidth
      return Math.floor(width / flatpickrOneMonthWith)
    }
}
