import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'collapsableArea',
    'checkbox',
    'toggleIndicatorPlaceholder',
    'toggleIndicator',
    'input',
  ]
  static values = { collapse: { type: Boolean, default: true } }

  // HOW TO USE //

  // ADD data-controller="collapse"

  // if toggle button is a switch, add data-collapse-target="checkBox" to the <input type=checkbox>
  // collapsableArea will hide if unchecked, if checked --> will show

  // Optionally add data-collapse-collapse-value="true/false"
  // for setting initial collapse state. If not provided, DEFAULT is collapsed
  // (true = initial state collapsed / false = initial state show)

  // Add data-collapse-target="collapsableArea" to the area, which you want collapsed
  // Add data-action="click->collapse#toggle" to the link, you want to space to uncollapse
  // Add DATAMAP on
  //  Add to href="" like this => href="javascript:void(0)" this will prevent <a> behavior.

  // INDICATOR:: If you want an arrow to indicate to the user the collapse / not collapse status. add
  // -->        data-collapse-target="toggleIndicatorPlaceholder"   <---
  // to the link initiating the toggle

  // COLLAPSE / UNCOLLAPSE WITH INPUT FIELDS
  // --> INPUT type='text'
  // For an input form to uncollapse after received first input / content.
  // Add data-collapse-target='input'
  // Add this action to get an uncollapse
  // action: 'change->collapse#uncollapseOnInput'
  // --> INPUT type=checkbox     e.g. in combination with switches
  // add to all checkboxes
  // 'data-action': 'click->collapse#toggle', 'data-collapse-target': 'checkbox'

  // ADD "collapseInverted" to class of areas, which you want invert collapse behavior.
  // By doing so ONE toggle can uncollapse some areas and collapse some others in parallel.

  connect() {
    if (this.hasCheckboxTarget && this.anyCheckboxeActive) {
      this.collapseValue = false
    }

    if (this.hasCollapsableAreaTarget) {
      for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
        if (!this.collapseValue) {
          if (
            this.collapsableAreaTargets[j].classList.contains(
              'collapseInverted',
            )
          ) {
            this.collapsableAreaTargets[j].hidden = true
          } else {
            this.collapsableAreaTargets[j].hidden = false
          }
        }
      }
    }

    if (this.hasToggleIndicatorPlaceholderTarget) {
      this.toggleIndicatorPlaceholderTarget.innerHTML =
        '<i class="fas fa-caret-right mr-2" data-collapse-target="toggleIndicator"></i>' +
        this.toggleIndicatorPlaceholderTarget.innerHTML
      if (!this.collapseValue) {
        this.toggleIndicatorTarget.classList.remove('fa-caret-right')
        this.toggleIndicatorTarget.classList.add('fa-caret-down')
      }
    }
  }

  toggle(event) {
    // in case of checkboxes, checks if any is already checked.
    // If already one is checked, prevent any action
    if (
      this.hasCheckboxTarget &&
      this.collapseValue == false &&
      this.anyCheckboxeActive
    ) { return }

    if (this.collapseValue) {
      this.uncollapse(event)
    } else {
      this.collapse(event)
    }
  }

  collapse(event) {
    this.collapseValue = true

    this.toggleIndicatorClose()

    for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
      if (
        this.collapsableAreaTargets[j].classList.contains('collapseInverted')
      ) {
        this.collapsableAreaTargets[j].hidden = false
      } else {
        this.collapsableAreaTargets[j].hidden = true
      }
    }
  }

  slideUpDown(e) {
    const attributeType = e.target.value
    const attributeClass = e.target.classList
    const inputElement = this.inputTarget
    if (
      attributeType == 'enum' ||
      attributeType == 'multiselect' ||
      attributeClass.contains('purchase-price-ex')
    ) {
      inputElement.style.maxHeight = inputElement.scrollHeight + 'px'
      inputElement.classList.remove('slide-up')
      inputElement.classList.add('slide-down')
    } else {
      inputElement.style.maxHeight = inputElement.offsetHeight + 'px'
      inputElement.classList.remove('slide-down')
      inputElement.classList.add('slide-up')
      setTimeout(function() {
        inputElement.style.maxHeight = '0'
      }, 50)
    }
  }

  uncollapse(event) {
    this.collapseValue = false

    this.toggleIndicatorOpen()

    for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
      if (
        this.collapsableAreaTargets[j].classList.contains('collapseInverted')
      ) {
        this.collapsableAreaTargets[j].hidden = true
      } else {
        this.collapsableAreaTargets[j].hidden = false
      }
    }
  }

  toggleIndicatorOpen() {
    if (this.hasToggleIndicatorTarget) {
      this.toggleIndicatorTarget.classList.remove('fa-caret-right')
      this.toggleIndicatorTarget.classList.add('fa-caret-down')
    }
  }

  toggleIndicatorClose() {
    if (this.hasToggleIndicatorTarget) {
      this.toggleIndicatorTarget.classList.remove('fa-caret-down')
      this.toggleIndicatorTarget.classList.add('fa-caret-right')
    }
  }

  uncollapseOnInput() {
    let i
    let desiredStateIsCollapsed = true

    for (i = 0; i < this.inputTargets.length; i++) {
      if (this.inputTargets[i].value.length > 0) {
        desiredStateIsCollapsed = false
        if (this.collapseValue) {
          this.uncollapse(event)
        }
        break
      }
    }
    if (desiredStateIsCollapsed && !this.collapseValue) {
      this.collapse(event)
    }
  }

  get anyCheckboxeActive() {
    // loops over multiple checkboxes. Uncollapses if just one is checked
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      if (this.checkboxTargets[i].checked) return true
    }
    return false
  }
}
