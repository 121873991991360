import { Controller } from 'stimulus'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Compressor from '@uppy/compressor'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import { isArray, isString, isEmpty } from 'lodash'
import isMobile from 'is-mobile'
import { setLocale, fileTypeMessage } from '../utils/uppy_helpers'
import { getCookie } from '../utils/cookies_helper'

import '@uppy/dashboard/dist/style.css'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/webcam/dist/style.min.css'

const DEFAULT_MAX_FILE_SIZE = 52000000 // 50 MB
const FILE_TYPE_OPTIONS = {
  'imagesOnly': ['image/*'],
  'default': ['image/*', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.pdf', '.zip', '.eml', '.msg', '.txt',
    '.mx3'],
}

// To add to model
// data-controller="uppy-webcam"

// To define where the uppy modal is loaded, optionally the following can be provided
// data-uppy-webcam-target="uppy-modal"
// if it is not provided, the uppy modal will be loaded where data-controller was defined

// MUST: Pass in the submit URL
// data-uppy-webcam-upload-url-value='#VALID URL TO WHERE FILES CAN BE SAVED'

// MUST: Pass in the fieldName (parameter structure, on how data is sent to backend)
// For multiple file uploads, we have to pass in []
// data-uppy-webcam-upload-field-name-value='checkoutable_item[check_out_documents][]' %>'

// Set compressor to compress file size. It will compress it value is passed else it will upload file as it is.
// data-uppy-compressor-value='1800'
// Can be set maximum limit of files to upload
// data-uppy-webcam-max-number-of-files-value='1'
// Pass allowed file type to upload
// data-uppy-webcam-allowed-file-types-value='<%= ['image/*'] %>',
// Want to allow multiple file uploads then pass
// data-uppy-webcam-multiple-upload-value='true'
// You can pass either string token from FILE_TYPE_OPTIONS const or
// or pass an array with only specific file types
// if no specified default filetypes will be allowed
// data-uppy-webcam-allowed-file-types-value="['image/*', '.csv']"
// data-uppy-webcam-allowed-file-types-value="imageOnly"

export default class extends Controller {
  static targets = ['uppyModal', 'progressBar', 'fileTable', 'fileTableTemplate',
    'uppy', 'appendNode', 'fileInput', 'sensitiveSwitch',
  ]
  static values = {
    uploadUrl: String,
    uploadFieldName: String,
    uploadMethod: { type: String, default: 'patch' },
    endpointFormat: { type: String, default: '.html' },
    multipleUpload: Boolean,
    appendRecord: Boolean,
    allowedFileTypes: Array,
    compressor: Number,
    maxFileSize: { type: Number, default: DEFAULT_MAX_FILE_SIZE },
    maxNumberOfFiles: Number,
    uploadSensitive: Boolean,
  }

  // To add to model
  // data-controller="uppy-webcam"

  // To define where the uppy modal is loaded, optionally the following can be provided
  // data-uppy-webcam-target="uppy-modal"
  // if it is not provided, the uppy modal will be loaded where data-controller was defined

  // MUST: Pass in the submit URL
  // data-uppy-webcam-upload-url-value='#VALID URL TO WHERE FILES CAN BE SAVED'

  // MUST: Pass in the fieldName (parameter structure, on how data is sent to backend)
  // For multiple file uploads, we have to pass in []
  // data-uppy-webcam-upload-field-name-value='checkoutable_item[check_out_documents][]'

  connect() {
    this.authenticity_token = document.getElementsByName('csrf-token')[0].content

    this.uppy = new Uppy({
      debug: false,
      locale: setLocale(),
      restrictions: this.getRestrictions(),
      autoProceed: true,
      logger: {
        debug: (...args) => {
          console.log(...args)
        },
        warn: (...args) => {
          console.log(...args)
        },
        error: (...args) => {
          console.log(...args)
        },
      },
    }).use(Dashboard, {
      inline: true,
      target: this.hasUppyModalTarget ? this.uppyModalTarget : this.element,
      note: this.showMessage(),
      proudlyDisplayPoweredByUppy: false,
      doneButtonHandler: null,
    }).use(Webcam, {
      target: Dashboard,
      showVideoSourceDropdown: true,
      modes: ['picture'],
      mobileNativeCamera: isMobile(),
      preferredImageMimeType: 'image/png',
      videoConstraints: {
        deviceId: getCookie('cameraId'),
      },
    })
    this.compressImage()
    this.uploadFile()
    this.showMessage()
    this.uppyOnComplete()
  }

  compressImage() {
    if (this.hasCompressorValue) {
      this.uppy.use(Compressor, {
        width: this.compressorValue,
        height: this.compressorValue,
      })
    }
  }

  toggleSensitive() {
    if (this.sensitiveSwitchTarget.checked) {
      this.uploadSensitiveValue = true
    } else {
      this.uploadSensitiveValue = false
    }

    this.uppy.setMeta({
      sensitive: this.uploadSensitiveValue,
    })
  }

  uploadFile() {
    const _this = this
    if (this.hasUploadUrlValue) {
      this.uppy.use(XHRUpload, {
        endpoint: this.uploadUrlValue.concat(this.endpointFormatValue),
        method: this.uploadMethodValue,
        formData: true,
        bundle: true, // send all files in a single multipart request, cp. https://uppy.io/docs/xhr-upload/
        fieldName: this.uploadFieldNameValue,
        headers: { 'X-CSRF-Token': this.authenticity_token },
        getResponseData(responseText, response) {
          if (_this.appendRecordValue && _this.hasAppendNodeTarget) {
            const val = _this.appendNodeTarget.insertAdjacentHTML('beforeend', responseText)

            // We always render attachment table/div, but initially hidden. So with first upload we make it visible:
            // e.g. _this.transformUploadFieldName(_this.uploadFieldNameValue) + '_list_div' = item_photos_list_div
            document.getElementById(_this.transformName(_this.uploadFieldNameValue) + '_list_div').style.display='inline'

            return { template: val }
          } else if (_this.hasAppendNodeTarget) {
            return {
              template: _this.appendNodeTarget.innerHTML = responseText,
            }
          } else if (document.getElementById('attachment')) {
            return {
              template: document.getElementById('attachment').innerHTML = responseText,
            }
          }
        },
      })
      this.uppy.on('upload-error', (file, error, response) => {
        this.onUploadError()
      })
      this.uppy.setMeta({
        sensitive: this.uploadSensitiveValue,
      })
    }
  }

  uppyOnComplete() {
    this.uppy.on('complete', (result) => {
      this.uppy.cancelAll()
    })
  }

  getRestrictions() {
    const restrictions = {}
    if (this.hasMaxNumberOfFilesValue) {
      restrictions['maxNumberOfFiles'] = this.maxNumberOfFilesValue
    }

    if (this.hasMaxFileSizeValue) {
      restrictions['maxFileSize'] = this.maxFileSizeValue
    }

    if (this.hasAllowedFileTypesValue) {
      if (isArray(this.allowedFileTypesValue) && !isEmpty(this.allowedFileTypesValue)) {
        restrictions['allowedFileTypes'] = this.allowedFileTypesValue
      } else if (isString(this.allowedFileTypesValue)) {
        restrictions['allowedFileTypes'] = FILE_TYPE_OPTIONS[this.allowedFileTypes]
      } else {
        restrictions['allowedFileTypes'] = FILE_TYPE_OPTIONS['default']
      }
    }

    return restrictions
  }

  onUploadError() {
    window.$.notify(window.I18n.t('js.saving_failed_try_again'), 'error')
  }

  allowedMultipleUpload() {
    return this.hasMultipleUploadValue && this.multipleUploadValue
  }

  showMessage() {
    if (this.hasAllowedFileTypesValue && !isEmpty(this.allowedFileTypesValue)) {
      return fileTypeMessage(this.allowedFileTypesValue)
    }
  }

  transformName(uploadFieldNameValue) {
    // Transform e.g. item[photos][] to item_photos:
    const basePart = uploadFieldNameValue.split('[')[0] // Extract the base part before the first bracket
    const bracketContent = uploadFieldNameValue.match(/\[(.*?)\]/)[1] // Extract the content within the brackets
    const newValue = `${basePart}_${bracketContent}` // Construct the new value
    return newValue
  }
}

